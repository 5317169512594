import React, {useRef, useState} from "react";
import ReactPlayer from "react-player";
import Duration from "./Duration";

export default function (props) {
    const {handleEnded, activityPArtId} = props;
    const [url, setUrl] = useState(props.url)
    const [pip, setPip] = useState(false)
    const [playing, setPlaying] = useState(true)
    const [controls, setControls] = useState(false)
    const [light, setLight] = useState(false)
    const [volume, setVolume] = useState(0.8)
    const [muted, setMuted] = useState(false)
    const [played, setPlayed] = useState(0)
    const [loaded, setLoaded] = useState(0)
    const [duration, setDuration] = useState(0)
    const [playbackRate, setPlaybackRate] = useState(1.0)
    const [loop, setLoop] = useState(false)
    const [seeking, setSeeking] = useState(false)
    const SEPARATOR = ' · '


    const player = useRef(null)

    const load = url => {

        setUrl(url)
        setPlayed(0)
        setLoaded(0)
        setPip(false)

    }


    const handlePlayPause = () => {
        setPlaying(!playing)
    }

    const handleStop = () => {
        setPlaying(false)
        setUrl(null)

    }

    const handleToggleControls = () => {
        setControls(!controls)
        load(url)

    }

    const handleToggleLight = () => {
        setLight(!light)
    }

    const handleToggleLoop = () => {
        setLoop(!loop)

    }

    const handleVolumeChange = e => {
        setVolume(parseFloat(e.target.value))
    }

    const handleToggleMuted = () => {
        setMuted(!muted)
    }

    const handleSetPlaybackRate = e => {
        setPlaybackRate(parseFloat(e.target.value))
    }

    const handleOnPlaybackRateChange = (speed) => {
        setPlaybackRate(parseFloat(speed))
    }

    const handleTogglePIP = () => {
        setPip(!pip)
    }

    const handlePlay = () => {
        console.log('onPlay')
        setPlaying(true)
    }

    const handleEnablePIP = () => {
        console.log('onEnablePIP')
        setPip(true)
    }

    const handleDisablePIP = () => {
        console.log('onDisablePIP')
        setPip(false)
    }

    const handlePause = () => {
        console.log('onPause')
        setPlaying(false)
    }

    const handleSeekMouseDown = e => {
        setSeeking(true)
    }

    const handleSeekChange = e => {
        setPlayed(parseFloat(e.target.value))
    }

    const handleSeekMouseUp = e => {
        setSeeking(false)
        player.seekTo(parseFloat(e.target.value))
    }

    const handleProgress = state => {
        console.log('onProgress', state)

        setPlayed(state.playedSeconds )
        setLoaded(state.loadedSeconds)
        // burası boş
    }



    const handleDuration = (duration) => {
        console.log('onDuration', duration)
        setDuration(duration)
    }

    const handleClickFullscreen = () => {
        //  screenfull.request(document.querySelector('.react-player'))
    }

    const renderLoadButton = (url, label) => {
        return (
            <button onClick={() => load(url)}>
                {label}
            </button>
        )
    }

    const ref = player => {
        player = player
    }
    
    
    console.log(url.indexOf("mp3"))
    
    return (
        <div className='app' style={{width:"50%"}}>
                <div className='player-wrapper'>
                    <ReactPlayer
                        ref={player}
                        className='react-player'
                        width='100%'
                        height={url.indexOf("mp3")>-1 ? "60px" : '100%'}
                        url={url}
                        pip={pip}
                        playing={false}
                        controls={true}
                        loop={false}
                        playbackRate={playbackRate}
                        onReady={() => console.log('onReady')}
                        onStart={() => console.log('onStart')}
                        onPlay={handlePlay}
                        onEnablePIP={handleEnablePIP}
                        onDisablePIP={handleDisablePIP}
                        onPause={handlePause}
                        onBuffer={() => console.log('onBuffer')}
                        onPlaybackRateChange={handleOnPlaybackRateChange}
                        onSeek={e => console.log('onSeek', e)}
                        onEnded={()=>handleEnded(activityPArtId)}
                        onError={e => console.log('onError', e)}
                        onProgress={handleProgress}
                        onDuration={handleDuration}
                        onPlaybackQualityChange={e => console.log('onPlaybackQualityChange', e)}
                        playIcon={ // Özel play butonu
                            <button
                                style={{
                                    backgroundColor: 'red', // İstediğiniz renk
                                    width: '100px', // İstediğiniz boyut
                                    height: '100px',
                                    borderRadius: '50%',
                                    border: 'none'
                                }}
                            >
                                Oynat
                            </button>
                        }
                    />
                </div>
        </div>

    )

}
