import api from "./api";

const createActivityPart = (activityId, activityPart) => {
    return api.post(`/exam/activity/part/${activityId}`, activityPart);
};

const getAllActivityPart = () => {
    return api.get("/exam/activity/part/");
};

const updateActivityPart = (activityPartId, activityPart) => {

    return api.put(`/exam/activity/part/${activityPartId}`, activityPart);
};

const getAllActiveActivityPart = () => {
    return api.get("/exam/activity/part/active/");
};

const getActivityPartById = (activityPartId) => {
    return api.get(`/exam/activity/part/${activityPartId}`);
};

const getActivityPartByActivity = (activityPartId) => {
    return api.get(`/activity/part/by-activity/${activityPartId}`);
};

const createActivity = (examId, activity) => {
    return api.post(`/exam/activity/${examId}`, activity);
};

const getAllActivity = () => {
    return api.get(`/exam/activity/`);
};

const updateActivity = (activityId, activity) => {
    return api.put(`/exam/activity/${activityId}`, activity);
};

const getAllActiveActivity = (userId) => {
    return api.get(`/activity/active/`);
};

const getActivityById = (activityId) => {
    return api.get(`/exam/activity/${activityId}`);
};

const getActivityByExam = (examId) => {
    return api.get(`/exam/activity/part/by-exam/${examId}`);
};

const addActivityPartToActivity = (activityId, activityPartId) => {
    return api.get(`/exam/activity/add-part/${activityId}/${activityPartId}`);
};

const removeActivityPartToActivity = (activityId, activityPartId) => {
    return api.get(`/exam/activity/remove-part/${activityId}/${activityPartId}`);
};

const createExam = (exam) => {
    return api.post(`/exam/`, exam);
};

const getAllExam = () => {
    return api.get(`/exam/`);
};

const updateExam = (examId, exam) => {
    return api.put(`/exam/${examId}`, exam);
};

const getAllActiveExam = () => {
    return api.get(`/exam/active/`);
};

const getExamById = (examId) => {
    return api.get(`/exam/${examId}`);
};

const addActivityToExam = (examId, activityId) => {
    return api.get(`/exam/activity/add-activity/${examId}/${activityId}`);
};

const removeActivityToExam = (examId, activityId) => {
    return api.get(`/exam/activity/remove-activity/${examId}/${activityId}`);
};

const startExam = (assignmentId) => {
    return api.get(`/exam/start/assignment/${assignmentId}`);
};


const ExamService = {
    createActivityPart,
    getAllActivityPart,
    updateActivityPart,
    getAllActiveActivityPart,
    getActivityPartById,
    getActivityPartByActivity,
    createActivity,
    getAllActivity,
    updateActivity,
    getAllActiveActivity,
    getActivityById,
    getActivityByExam,
    addActivityPartToActivity,
    removeActivityPartToActivity,
    createExam,
    getAllExam,
    updateExam,
    getAllActiveExam,
    getExamById,
    addActivityToExam,
    removeActivityToExam,
    startExam
};
export default ExamService;
