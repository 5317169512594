import React from 'react'
const image404 = require("../../../assets/404.png")
export default function NoMatch() {

  return (
    <main>
    <section className="d-flex align-items-center justify-content-center">
      <div className="container">
        <div className="row">
          <div
            className="col-12 text-center d-flex align-items-center justify-content-center"
          >
            <div>
              <img
                className="img-fluid w-75"
                src={image404}
                alt="404 not found"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  )
}
