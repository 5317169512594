import { HiStatusOnline } from "react-icons/hi";
import Report from "../app/pages/Report";
import ComponentsList from "../app/pages/ComponentsList";
import Exam from "../app/pages/exam/Exam";
import HomeWork from "../app/pages/HomeWork";
import Lesson from "../app/pages/Lesson";
import LiveLesson from "../app/pages/LiveLesson";
import Message from "../app/pages/Message";
import Plan from "../app/pages/Plan";
import Tutor from "../app/pages/Tutor";

import {
  IconAdd,
  IconAlert, IconBuy,
  IconCalendar,
  IconComponents,
  IconDashboard,
  IconDocumentation, IconGrayArchive, IconGraySnooze, IconHomeNavigation,
  IconKanban, IconListOpen, IconMap,
  IconMessages,
  IconPage, IconPlus,
  IconSettings,
  IconTables, IconTask, IconTheme,
  IconTransactions, IconTrash,
  IconUsers,
  IconWidgets
} from "../app/pages/components/Icons";
import SingIn from "../app/pages/single/SingIn";
import SingUp from "../app/pages/single/SingUp";
import ResetPassword from "../app/pages/single/ResetPassword";
import Lock from "../app/pages/single/Lock";
import NoMatch from "../app/pages/single/NoMatch";
import Error from "../app/pages/single/Error";
import ForgotPassword from "../app/pages/single/ForgotPassword";
import DoExam from "../app/pages/exam/DoExam";
import ActivityPartEdit from "../app/pages/exam/ActivityPartEdit";
import ExamEdit from "../app/pages/exam/ExamEdit";
import ActivityEdit from "../app/pages/exam/ActivityEdit";
import StudentExamList from "../app/pages/exam/StudentExamList";
import FinishExam from "../app/pages/single/FinishExam";
import DoExamPart from "../app/pages/exam/DoExamPart";

const dashboardRoutes = [

  {
    path: "/:assignmentId",
    name: "Dashboard",
    icon: IconUsers,
    component: StudentExamList,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 0,
    sidePanelVisible: true,
    fullScreen: false
  },


  {
    path: "/",
    name: "Dashboard",
    icon: IconUsers,
    component: Exam,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 0,
    sidePanelVisible: true,
    fullScreen: false
  },

  {
    path: "/courses",
    name: "Exams",
    icon: IconUsers,
    component: Exam,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: true,
    fullScreen: false
  },
  {
    path: "/document",
    name: "Applications",
    icon: IconUsers,
    component: Document,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: true,
    fullScreen: false
  },
  {
    path: "/document/:id",
    name: "Report",
    icon: IconUsers,
    component: Exam,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: true,
    fullScreen: false
  },
  {
    path: "/exam",
    name: "Exam",
    icon: IconUsers,
    component: Exam,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },
  {
    path: "/exam/:id",
    name: "Do Exam",
    icon: IconUsers,
    component: DoExam,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },

  {
    path: "/exam/:id/:qNumber",
    name: "Do Exam",
    icon: IconUsers,
    component: DoExamPart,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },

  {
    path: "/exam/:examId/:activityId/:activityPartId",
    name: "HomeWork",
    icon: IconUsers,
    component: ActivityPartEdit,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },

  {
    path: "/exam/:examId/:activityId",
    name: "HomeWork",
    icon: IconUsers,
    component: ActivityEdit,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },

  {
    path: "/exam/create/:examId",
    name: "HomeWork",
    icon: IconUsers,
    component: ExamEdit,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },


  {
    path: "/lesson",
    name: "Lesson",
    icon: IconUsers,
    component: Lesson,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },
  {
    path: "/live",
    name: "Live Lesson",
    icon: IconUsers,
    component: LiveLesson,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },
  {
    path: "/message",
    name: "Message",
    icon: IconUsers,
    component: Message,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },
  {
    path: "/plan",
    name: "Plan",
    icon: IconUsers,
    component: Plan,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },
  {
    path: "/tutor",
    name: "Tutor",
    icon: IconUsers,
    component: Tutor,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },






  {
    path: "/report",
    name: "Report",
    icon: IconComponents,
    renderIcon: HiStatusOnline,
    component: Report,
    menu: true,
    location: "sidebar",
    overPath: "EXAMS",
    auth: ["ADMIN"],
    sort: 10,
    sidePanelVisible: false,
    fullScreen: false
  },



  {
    path: "/componentList",
    name: "Component List",
    icon: IconDashboard,
    renderIcon: HiStatusOnline,
    component: ComponentsList,
    menu: true,
    location: "sidebar",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 30,
    sidePanelVisible: false,
    fullScreen: false
  },

  {
    path: "/map",
    name: "Map View",
    icon: IconTransactions,
    component: Map,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 30,
    sidePanelVisible: false,
    fullScreen: false
  },

  {
    path: "/sing-in",
    name: "Sing In",
    icon: IconDashboard,
    renderIcon: HiStatusOnline,
    component: SingIn,
    menu: false,
    location: "sidebar",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 30,
    sidePanelVisible: false,
    fullScreen: true
  },
  {
    path: "/sing-up",
    name: "Sing Up",
    icon: IconDashboard,
    renderIcon: HiStatusOnline,
    component: SingUp,
    menu: false,
    location: "sidebar",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 30,
    sidePanelVisible: false,
    fullScreen: true
  },
  {
    path: "/reset",
    name: "Reset Password",
    icon: IconDashboard,
    renderIcon: HiStatusOnline,
    component: ResetPassword,
    menu: false,
    location: "sidebar",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 30,
    sidePanelVisible: false,
    fullScreen: true
  },
  {
    path: "/lock",
    name: "Lock",
    icon: IconDashboard,
    renderIcon: HiStatusOnline,
    component: Lock,
    menu: false,
    location: "sidebar",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 30,
    sidePanelVisible: false,
    fullScreen: true
  },
  {
    path: "/404",
    name: "404",
    icon: IconDashboard,
    renderIcon: HiStatusOnline,
    component: NoMatch,
    menu: false,
    location: "sidebar",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 30,
    sidePanelVisible: false,
    fullScreen: true
  },
  {
    path: "/finish",
    name: "finish",
    icon: IconDashboard,
    renderIcon: HiStatusOnline,
    component: FinishExam,
    menu: false,
    location: "sidebar",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 30,
    sidePanelVisible: false,
    fullScreen: true
  },
  {
    path: "/error",
    name: "Error",
    icon: IconDashboard,
    renderIcon: HiStatusOnline,
    component: Error,
    menu: false,
    location: "sidebar",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 30,
    sidePanelVisible: false,
    fullScreen: true
  },
  {
    path: "/forgot",
    name: "Forgat Password",
    icon: IconDashboard,
    renderIcon: HiStatusOnline,
    component: ForgotPassword,
    menu: false,
    location: "sidebar",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 30,
    sidePanelVisible: false,
    fullScreen: true
  },

];

export default dashboardRoutes;
