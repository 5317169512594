import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ExamService from '../../service/exam.service';
import ResultService from '../../service/result.service';
import stringSimilarity from 'string-similarity';
import config from '../../../configs/config.json';

const ExamHeader = ({ exam }) => (
    <div className="row justify-content-center mt-3">
        <div className="col-12">
            <div className="card border-0 shadow p-4 mb-4">
                <div className="d-flex justify-content-between align-items-center mb-2">
          <span className="font-small">
            <Link to="#">
              <h1 className="fw-bold">{exam.name}</h1>
            </Link>
          </span>
                </div>
                <p className="m-0">{exam.explain}</p>
            </div>
        </div>
    </div>
);

const QuestionContent = ({ ques, getSpeechText }) => {
    const renderContent = () => {
        switch (ques.type) {
            case 'TEXT':
                return ques.content.split("\n").map((line, key) => (
                    <div key={key}>{line}</div>
                ));
            case 'AUDIO':
                return <AudioPlayer ques={ques} />;
            case 'VIDEO':
                return <VideoPlayer ques={ques} />;
            case 'IMAGE':
                return <ImageDisplay ques={ques} />;
            default:
                return null;
        }
    };

    return (
        <div className="form-check alert alert-light border border-gray w-100">
            {renderContent()}
            {ques.isMain && (
                <SpeechText
                    getSpeechText={getSpeechText}
                    questionNumber={ques.id}
                />
            )}
        </div>
    );
};

const DoExamPart = () => {
    const [exam, setExam] = useState(null);
    const [qNumber, setQNumber] = useState(-1);
    const [question, setQuestion] = useState(null);
    const [examResult, setExamResult] = useState(null);
    const [examResultActivityPart, setExamResultActivityPart] = useState([]);
    const [student, setStudent] = useState(null);

    const navigate = useNavigate();

    // Utility functions
    const clearText = (readingText) =>
        String(readingText)
            .replace(/[.,!?'_-]/g, '')
            .toLowerCase();

    const getUrl = (url) => `${config.api.baseUrl}/sample/${url}`;

    // Effect to load exam
    useEffect(() => {
        const assignmentId = localStorage.getItem("assignmentId");
        ExamService.startExam(assignmentId).then((response) => {
            const { examResult, examResultActivityPart, student, exam } = response.data;
            setExamResult(examResult);
            setExamResultActivityPart(examResultActivityPart);
            setStudent(student);
            setExam(exam);
        });
    }, []);

    // Effect to set initial question
    useEffect(() => {
        if (exam?.activities?.length && qNumber === -1) {
            setQNumber(0);
            setQuestion(exam.activities[0]);
        }
    }, [exam]);

    // Effect to update question
    useEffect(() => {
        if (qNumber > -1) {
            setQuestion(exam.activities[qNumber]);
        }
    }, [qNumber]);

    // Question navigation
    const handleQuestionSelect = (value) => {
        if (value === -1 && qNumber > 0) {
            setQNumber(prev => prev - 1);
        } else if (value === 1 && qNumber < exam.activities.length - 1) {
            setQNumber(prev => prev + 1);
        }
    };

    // Speech text comparison
    const getSpeechText = (text, questionNumber) => {
        const selectedAnswer = question.parts.find(part => part.id === questionNumber).answer;
        const similarity = stringSimilarity.compareTwoStrings(
            clearText(text),
            clearText(selectedAnswer)
        );

        const activityPartResult = examResultActivityPart.find(
            part => part.activityPart.id === questionNumber
        );

        const score = activityPartResult.score;
        const studentScore = parseInt(similarity * 100) * score / 100;

        if (!activityPartResult.studentScore || studentScore > activityPartResult.studentScore) {
            activityPartResult.studentAnswer = text;
            activityPartResult.studentScore = studentScore;

            ResultService.addActivityPartResult(activityPartResult).then((response) => {
                setExamResultActivityPart(response.data);
            });
        }
    };

    // Render
    if (!exam) return <div>Loading...</div>;

    return (
        <div className="container">
            <ExamHeader exam={exam} />

            {qNumber > -1 && question && (
                <div className="row justify-content-center mt-3">
                    <div className="col-12">
                        <div className="card border-0 shadow p-4 mb-4">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <h4 className="fw-bold">
                                    SECTION {qNumber + 1}: {question.activityGroup.replace(/_/g, ' ')}
                                </h4>
                            </div>

                            {question.parts?.sort((a, b) => a.orderNumber - b.orderNumber).map((ques) => (
                                <div key={ques.id} className="mt-2 w-100">
                                    <QuestionContent
                                        ques={ques}
                                        getSpeechText={getSpeechText}
                                    />
                                </div>
                            ))}
                        </div>

                        <div className="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                            <nav>
                                <ul className="pagination mb-0">
                                    <li className="page-item">
                                        <button
                                            className="page-link"
                                            onClick={() => handleQuestionSelect(-1)}
                                            disabled={qNumber === 0}
                                        >
                                            Previous
                                        </button>
                                    </li>

                                    {exam.activities.map((_, key) => (
                                        <li key={key} className="page-item">
                                            <button
                                                className={`page-link ${qNumber === key ? 'bg-success' : ''}`}
                                                onClick={() => setQNumber(key)}
                                            >
                                                {key + 1}
                                            </button>
                                        </li>
                                    ))}

                                    <li className="page-item">
                                        {qNumber < exam.activities.length - 1 ? (
                                            <button
                                                className="page-link"
                                                onClick={() => handleQuestionSelect(1)}
                                            >
                                                Next
                                            </button>
                                        ) : (
                                            <Link
                                                className="page-link btn-danger"
                                                to="/finish"
                                            >
                                                Finish Exam
                                            </Link>
                                        )}
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DoExamPart;

// Note: Separate components for media players would be defined here
const AudioPlayer = ({ ques }) => { /* Implementation */ };
const VideoPlayer = ({ ques }) => { /* Implementation */ };
const ImageDisplay = ({ ques }) => { /* Implementation */ };
const SpeechText = ({ getSpeechText, questionNumber }) => { /* Implementation */ };