import React, {useContext, useEffect, useState} from "react";
import {Link, Navigate} from "react-router-dom";
import {AppContext} from "../../../configs/AppContextProvider";
import {LuPencilLine} from "react-icons/lu";
import {VscDebugStart} from "react-icons/vsc";
import ExamService from "../../service/exam.service";

export default function Exam() {
    const [examList, setExamList] = useState([]);
    const [user, setUser] = useState(null);
    const {student, exam, setExamData} =
        useContext(AppContext);


    useEffect(() => {
        const assignmentId = localStorage.getItem("assignmentId");
        ExamService.startExam(assignmentId).then((response) => {
            setExamData(response.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const examRow = () => {

        return (


            <div className="container mt-5 mb-3">
                <div className="row">


                    <div className="col-md-12">
                        <div className="card p-3 mb-2">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                    <div className="icon"><LuPencilLine color={"blue"}/></div>
                                    <div className="ms-2 c-details">
                                        <h6 className="mb-0">{student?.name} {student?.lastName}</h6>
                                        <span>{student?.school} </span><span> {student?.grade} </span><span> {student?.branch} </span>
                                    </div>
                                </div>
                                <div className="badge"><span>{exam?.status}</span></div>
                            </div>
                            <div className="mt-5">
                                <h3 className="heading">{exam?.name}<br/></h3><br/>
                                <h5>{exam?.explain} </h5>

                                <div className="mt-5">

                                        {
                                            // <div className="progress"><div className="progress-bar" role="progressbar" style={{ width: "50%", ariaValuenow: "50",  ariaValuemin: "0",  ariaValuemax: "100" }}></div></div>
                                        }
                                    {
                                        //<div className="mt-3"><span className="text1">0 Applied <span className="text2">of {exam?.activities?.length} capacity</span></span></div>
                                    }


                                </div>
                                <div className="d-flex justify-content-end">
                                    <Link className="btn btn-success text-white" to={`/exam/${exam?.id}`}>
                                        <VscDebugStart size={28}/>
                                        <span className={"p-3"}>Start Exam</span>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        )
    };


    return (
        <div className="container">
            {student === null || exam === null ? (
                <Navigate to="/error/studentNotFound"/>
            ) : null}
            <div className="card card-body shadow border-0 table-wrapper table-responsive mt-3">

                {exam ? examRow() : null}
            </div>
        </div>
    );
}
